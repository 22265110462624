body[data-template='works'] {

  #projects-list {
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    hr {
      display: none;
      @include md-breakpoint {
        display: block;
      }

    }

    .works-item {
      position: relative;
      transition: opacity 0.3s;
      overflow: hidden;

      @include md-breakpoint {

      }

      a {
        display: flex;
        flex-direction: column;

        @include md-breakpoint {
          padding: clamp(20px, 2vw, 40px);
          /*height: clamp(100px, 17vw, 350px);*/
          height: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          border-radius: $media-border-radius;
          border: 5px solid $dark;
        }

      }

      .item-media {
        border-radius: $media-border-radius;
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
        overflow: hidden;
        @include md-breakpoint {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-text {
        display: flex;
        flex-direction: column;
        @include md-breakpoint {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .item-title-desc {
          display: flex;
          flex-direction: column;
        }

        .item-recognition {
          @include md-breakpoint {
            text-align: right;
          }

          p.recognition {
            display: none;
            @include md-breakpoint {
              display: block;
            }
          }

        }
      }

    }

  }

  #projects-media {
    display: none;

    @include md-breakpoint {
      display: block;
      mix-blend-mode: lighten;
      transition: background 0.3s;
      /*z-index: 3;*/
      position: fixed;
      top: 0;
      left: 0;
      aspect-ratio: 16/9;
      border-radius: $media-border-radius;
      overflow: hidden;
      width: clamp(500px, 50vw, 1200px);
      pointer-events: none;

      .item-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
body[data-template='error-404'] {
  section#hero {
    padding-top: 80px;
    padding-bottom: 80px;
    height:100vh;
    display:flex;
    flex-direction: row;


    @include md-breakpoint {
      padding-top: clamp(100px, 8vw, 300px);
      padding-bottom: clamp(100px, 8vw, 300px);
    }

    .text {
      display:flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 20px;
      > * {
        line-height: 1;
      }
    }
    .video {
      border-radius: 50%;
      overflow: hidden;
      aspect-ratio: 1/1;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }

    .subtitle {
      font-size: clamp(30px, 3vw, 60px);
      font-weight: 600;
    }



  }
}
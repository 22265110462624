body[data-template='home'] {

  background-color: $cream;

  section#hero {
    background-color: $cream;
    min-height: 80vh;
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: min(100px, 16vw);
    @include md-breakpoint {
      margin-top: 0;
      min-height: 100vh;
      position: fixed;
      top: 0;
      width: 100%;
      gap: clamp(60px, 160px, 3.5vw);
      padding-top: 0;
      padding-bottom: 0;
    }

    &.disable {
      opacity: 0;
      pointer-events: none;
    }

    & > .wrapper {
      width: 100%;
      min-height: auto;
      align-items: flex-start;
      /*padding-top: 100px;*/
      /*padding-bottom: 60px;*/
      gap: 20px;

      @include md-breakpoint {
        padding-top: 0;
        padding-bottom: 0;
        gap: 40px;
      }
    }

    h1 {
      font-kerning: none; //for splittype to behave

      & > span {
        display: block;
        width: 100%;
      }

      & > span:not(#text-ticker) {
        display: block;
        overflow: hidden;

        .chars {
          overflow: hidden;
        }
      }

    }

    #text-ticker-list {
      display: block;
      position: relative;

      span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transform: translateY(-8%);

        &:not(:nth-child(1)) {
          transform: translateY(92%);
        }

        .char {
          display: inline-block;
        }
      }
    }

    #text-ticker {
      overflow: hidden;
    }

    .description {
      flex-direction: column;
      gap: 20px;
      @include md-breakpoint {
        flex-direction: row;
        width: 100%;
        gap: 0;
      }

      .description-text {
        will-change: transform;
        font-kerning: none; //for splittype to behave
        .outer {
          overflow: hidden;
        }
      }

      .disciplines {

        li {
          overflow: hidden;
        }

      }

    }

    .scroll {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      p {
        font-size: clamp(18px, 2vw, 50px);
      }

      svg {
        width: clamp(18px, 2vw, 50px);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke: $dark;
        animation: bobbing 2s ease-in-out infinite;
      }

      @keyframes bobbing {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px); /* Adjust this value to control the bobbing height */
        }
        100% {
          transform: translateY(0);
        }
      }

      span {
        &.touch-only {
          display: none;
        }

        @media (hover: none) and (pointer: coarse) {
          &.touch-only {
            display: inline-block;
          }
        }

        &.pointer-only {
          display: none;
        }

        @media (pointer: fine) {
          &.pointer-only {
            display: inline-block;
          }
        }
      }
    }
  }

  /* Featured Items Grid */
  #featured-grid {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 100vh;
    z-index: 1;
    position: relative;
    @include md-breakpoint {
      z-index: 1; //lift above hero above that is fixed for ani
      position: relative;
      margin-top: 100vh;
      padding-top: 150px;
      padding-bottom: 150px;
    }


    .title {
      margin-bottom: 20px;
      @include md-breakpoint {
        margin-bottom: 50px;
      }

      .outer {
        overflow: hidden;
      }

      h2 {
        font-kerning: none; //for splittype to behave
      }
    }

    .projects-grid {
      display: flex;
      flex-direction: column;

      @include md-breakpoint {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
      }


    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 2;
      @include md-breakpoint {
        background: white;
      }
    }


    .grid-item {
      overflow: hidden;
      cursor: none;
      @include md-breakpoint {
        border-radius: $media-border-radius;
      }

      & > a {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
      }

      .image {
        border-radius: $media-border-radius;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @include md-breakpoint {
          border-radius: 0;
        }

        .img-wrapper {
          width: 110%;
          height: 110%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }

        img {
          @include xs-breakpoint {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: relative;
        }
      }

      .video {
        display: none;

        @include md-breakpoint {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          /*height:100%;*/
          object-fit: cover;
        }


      }

      div.image, video {
        aspect-ratio: 1/1;
      }

      &:nth-of-type(1) {
        grid-area: 1/1/4/4;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 1/1;
          }
        }
      }

      &:nth-of-type(2) {
        grid-area: 1/4/3/7;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 16/9;
          }
        }
      }

      &:nth-of-type(3) {
        grid-area: 1/7/7/10;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 9/16;
          }
        }

        video {
          height: 100%;
        }
      }

      &:nth-of-type(4) {
        grid-area: 4/1/7/4;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 16/9;
          }
        }
      }

      &:nth-of-type(5) {
        grid-area: 3/4/7/7;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 1/1;
          }
        }
      }

      &:nth-of-type(6) {
        grid-area: 7/1/13/4;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 9/16;
          }
        }

        video {
          height: 100%;
        }
      }

      &:nth-of-type(7) {
        grid-area: 7/4/11/7;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 1/1;
          }
        }
      }

      &:nth-of-type(8) {
        grid-area: 7/7/11/10;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 1/1;
          }
        }
      }

      &:nth-of-type(9) {
        grid-area: 11/4/13/7;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 16/9;
          }
        }
      }

      &:nth-of-type(10) {
        grid-area: 11/7/13/10;

        div.image, video {
          @include md-breakpoint {
            aspect-ratio: 16/9;
          }
        }
      }
    }

  }

}
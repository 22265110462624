body[data-template='project'] {

  main#main-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @include md-breakpoint {
      gap: 100px;
    }

  }


  /*Various image & text layouts*/

  $mobile-space: 40px;
  $mobile-image-group-gap: 10px;
  $desktop-image-group-gap: 1vw;


  & > div {
    overflow: hidden;
  }

  .progress-bar {
    width: 100%;
    height: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    background: transparentize($dark, .95);

    span {
      display: block;
      width: 100%;
      height: 100%;
      background: transparentize($dark, .5);
      transform-origin: left;
    }
  }

  section.text-block {
    gap: 15px;

    @include sm-breakpoint {
      gap: min(25px, 30px);
      padding-top: 0;
      padding-bottom: 0;
    }

  }

  section.project-media-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include sm-breakpoint {
      align-items: flex-start;
      display: flex;
      gap: $desktop-image-group-gap;
      &:not(.f-col) {
        flex-direction: row;
      }
    }

    .image-group-title {

      @include sm-breakpoint {
        margin-bottom: $desktop-image-group-gap;
      }

    }

    .brand-identity-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $mobile-image-group-gap;

      @include sm-breakpoint {
        grid-template-columns: repeat(6, 1fr);
        gap: $desktop-image-group-gap;
      }

      div {
        overflow: hidden;
        border-radius: $media-border-radius;
        @include sm-breakpoint {
          &:nth-of-type(1) {
            grid-area: 1/1/2/2;
          }

          &:nth-of-type(2) {
            grid-area: 2/1/4/3;
          }

          &:nth-of-type(3) {
            grid-area: 2/3/4/5;
          }

          &:nth-of-type(4) {
            grid-area: 2/5/3/6;
          }

          &:nth-of-type(5) {
            grid-area: 3/6/3/7;
          }

          &:nth-of-type(6) {
            grid-area: 4/2/4/3;
          }

          &:nth-of-type(7) {
            grid-area: 4/3/5/5;
          }

          &:nth-of-type(8) {
            grid-area: 4/5/5/7;
          }
        }
      }


    }

    .prime-student-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $mobile-image-group-gap;

      @include sm-breakpoint {
        grid-template-columns: repeat(6, 1fr);
        gap: $desktop-image-group-gap;
      }

      & > div {
        overflow: hidden;
        border-radius: $media-border-radius;

        @include sm-breakpoint {
          &:nth-of-type(1) {
            grid-area: 1/1/4/4;
          }

          &:nth-of-type(2) {
            grid-area: 1/4/3/6;
          }

          &:nth-of-type(3) {
            grid-area: 1/6/2/7;
          }

          &:nth-of-type(4) {
            grid-area: 3/4/4/5;
          }

        }


      }


    }

    .image-group {
      gap: $mobile-image-group-gap;
      width: 100%;
      @include md-breakpoint {
        gap: $desktop-image-group-gap;
      }

      & > div.flex {
        gap: $mobile-image-group-gap;
        align-items: flex-start;
        @include md-breakpoint {
          gap: $desktop-image-group-gap;
        }
      }
    }


  }

  @media (pointer: coarse) {

    .feature-video, .dragPic, .drag-pan-pic > div {
      position: relative;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        background: $cream;
        padding: 10px 20px 0 0;
        border-radius: 0 $media-border-radius 0 0;
        z-index: 10;
      }
    }

    .dragPic, .drag-pan-pic > div {
      &:after {
        content: 'Swipe to explore';
      }
    }
    .feature-video {
      &:after {
        content: 'Tap to Play';
      }
    }
  }

  .video-container {
    position: relative;
    width: 100%;

    video {
      display: block;
      width: 100%;
    }

    .video-teaser-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .project-media-block.drag-pan-pic {
    overflow: hidden;
    @include xs-breakpoint {
      height: 500px;
    }

    & > div {
      position: relative;
      @include xs-breakpoint {
        height: 500px;
      }
    }

    img {
      width: 1000px;
      height: auto;
      @include lg-breakpoint {
        width: 5000px;
        height: auto;
        /*max-width:120vw;*/
      }
    }


  }

  .nested-column {
    gap: $mobile-image-group-gap;
    display: flex;
    flex-direction: column;
    @include sm-breakpoint {
      gap: $desktop-image-group-gap;
    }
  }

  .img {
    overflow: hidden;
  }
/*
  .img-square {
    aspect-ratio: 1/1;
    overflow: hidden;
  }

  .img-2-1 {
    aspect-ratio: 2/1;
    overflow: hidden;
  }

  .img-1-2 {
    aspect-ratio: 1/2;
    overflow: hidden;
  }

  .img-16-9 {
    aspect-ratio: 16/9;
    overflow: hidden;
    // Fallback
    @supports not (aspect-ratio: 16 / 9) {
      &::before {
        float: left;
        padding-top: 56.25%;
        content: "";
      }

      &::after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }*/

  @include xs-breakpoint {
    .mob-img-square {
      aspect-ratio: 1/1;
      overflow: hidden;
    }
  }

  .video-container, .img-square, .img-16-9, .img-2-1, .img-1-2 {
    border-radius: $media-border-radius;
    overflow: hidden;
  }

  .img-square, .img-16-9, .img-2-1, .img-1-2 {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform-origin: center;
    }
  }

  .dragPic {
    overflow: hidden;
    position: relative;
    @include xs-breakpoint {
      aspect-ratio: 1/1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
      max-width: none;
      transform-origin: left;
    }

  }


  /* Other Projects - footer */

  section#other-projects {
    background: white;
    padding: 50px 0;
    overflow:hidden;

    @include sm-breakpoint {
      padding: 100px 0;
    }

    & > .wrapper {
      gap: 40px;
      @include sm-breakpoint {
        gap: 3vw;
      }
    }

    .projects {
      gap: 1vw;
      @include xs-breakpoint {
        flex-direction: column;
        gap: 40px;
      }


      .project {
        a {
          overflow: hidden;
          position:relative;
          gap: 1vw;
          & > div:first-of-type {
            overflow: hidden;
            position: relative;
            border-radius: $media-border-radius;
          }
        }
        video {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          aspect-ratio: 16/9;

        }
        .image {
          position: relative;
          z-index: 1;
        }

      }
    }


  }
}


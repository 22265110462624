#entrance-overlay {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;
  inset: 0;
  pointer-events: none;

  &.hide {
    display:none;
  }

/*  display: none;*/

/*  h1 {
    display: none;
    color: $cream;
    text-align: center;

    //max-width: 80vw;
    z-index: 1;
    overflow:hidden;
    line-height: 1.2;
    font-kerning: none;
    !*mix-blend-mode: difference;*!
    &.greeting {
      font-size: clamp(50px, 300px, 14vw);
    }
    &.emoji {
      font-size: clamp(50px, 200px, 10vw);
      font-family:serif;
    }
  }*/

  #text-splash {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    clip-path: url(#text-mask);
    color: white;
    gap: 2vw;
    img {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      object-fit: cover;
      mix-blend-mode: screen;
    }
    /*background-image:url('../images/bg.png');*/
  }

svg#initials {
  width:80%;
  max-width: 800px;
  path {
    display:none;
    fill:$cream;
  }
}


  svg#mask {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    #text-mask {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }

  }


}
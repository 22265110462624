#contact-overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  pointer-events: none;

  &.active {
    pointer-events: all;
  }

  .title, .message, a {
    //font-kerning: none;
  }

  .bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    z-index: 0;
    opacity: 0.95;
  }

  .content, .close {
    z-index: 10;
  }

  .content {
    color: $cream;
    width: 90vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 20px;
    @include md-breakpoint {
      width: 65vw;
      gap: 2vw;
    }

    .title {
      font-weight: 700;
      font-size: clamp(50px,6vw,200px);
      line-height: 1;

      & > div {
        overflow: hidden;
      }
    }

    .text {
      gap: 20px;
      @include md-breakpoint {
        gap: 1vw;
      }
    }

    .links {
      align-items: flex-start;
      gap: 20px;
      @include md-breakpoint {
        gap: 2vw;
      }
      & > div {
        transition:opacity 0.2s;
        &:hover {
          opacity:0.5;
        }
      }
    }

    .message {
      font-weight: 600;
      font-size: clamp(30px, 3vw, 65px);
      line-height: 1.2;
      opacity: 0.5;


      & > div {
        overflow: hidden;
      }
    }

    a {
      font-weight: 600;
      line-height: 1;
      font-size: clamp(30px, 3vw, 65px);

    }

  }

  .close {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;


    @include md-breakpoint {
      top: 80px;
      right: 80px;
      width: 80px;
      height: 80px;
    }

    path {
      fill: $cream;
    }
  }
}
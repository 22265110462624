#project-hero {
  padding-top: 80px;
  padding-bottom: 80px;

  @include md-breakpoint {
    padding: clamp(100px, 8vw, 300px) 0;
  }

  & > .wrapper {
    gap: 20px;
    @include md-breakpoint {
      gap: 40px;
    }
  }

  .title {
    h1, .subtitle {
      overflow: hidden;
      line-height: 1.2;

      span {
        display: block;
        font-kerning: none;
      }
    }

    .subtitle {
      font-size: clamp(30px, 3vw, 60px);
      font-weight: 600;
    }
  }

  .passage {
    @include sm-breakpoint {
      width: 66.6666666667%;
    }
    @include xl-breakpoint {
      width:50%;
    }

    p {
      font-kerning: none;
    }

    p > div {
      overflow: hidden;
    }
  }
}
html {
  @include brand-font;
  text-rendering: geometricPrecision;
  margin: 0;
  color: $dark;
}

body {
  min-height: 100vh;
}

main {
  background-color: $cream;
  position: relative;
  z-index: 3;
}

.wrapper {
  padding: 0 20px;
  width: 100%;
  /*max-width: 1920px;*/
  /*max-width: max(80vw, 1920px);*/
  max-width: max(70vw,2200px);
  margin-left: auto;
  margin-right: auto;

  @include sm-breakpoint {
    padding: 0 40px;
  }

  @include md-breakpoint {
    padding: 0 3em;
  }

  &.restrict-width {
    max-width: 80%;
  }
}

.touch-only {
  display: none;
}
@media (hover: none) and (pointer: coarse) {
  .touch-only {
    display: block;
  }
}

.pointer-only {
  display: none;
}

@media (pointer: fine) {
  .pointer-only {
    display: block;
  }
}


/* GLOBAL SPACING */


/* END GLOBAL SPACING */



#cursor {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: orangered;
  mix-blend-mode: difference;
  overflow: hidden;
  transform: translate(-50%, -50%) scale(0);
  @include md-breakpoint {
    display: flex;
  }

  span {
    position: absolute;
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    color: black;
    mix-blend-mode: darken;
    width: 100%;
    padding:20px;
  }

  &.active {
    cursor: none;
  }


}

.cursorTextSet, .cursorTextSet > * {
  cursor: default;
  @include md-breakpoint {
    cursor: none;
  }
}

img {
  width: 100%;
  display: block;
}

hr {
  border: none;
  border-top: 5px solid $dark;
  width: 100%;
  margin:0;

  &.light {
    border-top: 5px solid $cream;
  }
}


#grid {

  @include sm-breakpoint {
    /*display: block;*/
  }
  display: none;
  z-index: 4;
  opacity: 0.1;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;

  .wrapper {
    width: 100%;
    height: 100%;
  }

  span {
    display: block;
    border-right: 1px solid red;
    height: 100%;

    &:first-of-type {
      border-left: 1px solid red;
    }
  }
}

.reveal-chars {
  font-kerning: none;

  & > div {
    /*overflow: hidden;*/
    clip-path: polygon(0 0, 100% 0, 100% 105%, 0% 105%)
    /*font-kerning: none;*/

  }
}

.reveal-lines {
  font-kerning: none;

  & > div {
    font-kerning: none;
    clip-path: polygon(0 0, 100% 0, 100% 105%, 0% 105%)
  }
}

header#main-nav {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 20px 0;
  mix-blend-mode: exclusion;
  z-index: 900;
  /*background:rgb(244 243 236 / 29%);*/
  /*backdrop-filter: blur(5px);*/

  @include md-breakpoint {
    padding: 30px 0;
  }

  .logo {
    font-weight: 600;

  }

  li {
    font-weight: 600;
  }

  .logo, li {
    background-color: black;
    color: $cream;
    font-size: 18px;
    line-height: 1;
    @include sm-breakpoint {
      font-size: clamp(30px, 1vw, 40px);
    }

    p {
      cursor: pointer;
      font-size: inherit;
      line-height: inherit;
    }

  }

  ul {
    gap: 1em;
  }

  nav li, .logo {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $cream;
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out;
      transform-origin: right;
      @include md-breakpoint {
        height: 4px;
      }
    }

    &.current-page {
      pointer-events: none;

      &:after {
        transform: scaleX(1);
      }
    }

    @include md-breakpoint {
      &:not(.current-page):hover {
        &:after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
  }

}
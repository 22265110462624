footer#main-footer {
  background: $dark;
  color: $cream;
  padding-bottom: 20px;
  padding-top:50px;
  z-index:1;
  overflow:hidden;
  position:sticky;
  bottom:0;
  @include md-breakpoint {
    position:sticky;
    bottom:0;
    padding-bottom: 20px;
    padding-top:100px;
  }

  & > div {
    gap:50px;
    @include md-breakpoint {
      gap: 100px;
    }
  }

  .large-cta {
    cursor:pointer;
    text-align: center;
    gap:20px;
    width:100%;
    @include md-breakpoint {
      gap: 30px;
    }

    span {
      width:8%;
      svg {
        fill:$cream;
        width:100%;
        vertical-align: middle;
      }
      @include md-breakpoint {
        display:none;
      }
    }

    p.main {
      font-weight: 800;
      font-size: clamp(50px, 12vw, 320px);
      line-height: 1.2;
      gap:20px;
    }

    p.signoff {
      font-size: clamp(20px, 2vw, 30px);
      font-weight: 600;
      line-height: 1;
      @include md-breakpoint {
        font-size: clamp(20px, 6vw, 30px);
        max-width: unset;
      }
    }
  }


  .footer-bottom-menu {
    width:100%;
    justify-content: space-between;
    p {
      font-size: 12px;
    }
    @include md-breakpoint {
      p {
        font-size:16px;
      }
    }

    a {
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}
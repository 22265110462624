
.img-square {
  aspect-ratio: 1/1;
  overflow: hidden;
}

.img-2-1 {
  aspect-ratio: 2/1;
  overflow: hidden;
}

.img-1-2 {
  aspect-ratio: 1/2;
  overflow: hidden;
}

.img-16-9 {
  aspect-ratio: 16/9;
  overflow: hidden;
  // Fallback
  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.grid {
  display: grid
}

.flex {
  display: flex;
}

.f-row {
  flex-direction: row;
}

.f-col {
  flex-direction: column;
}

@include xs-breakpoint {
  .mob-row {
    flex-direction:row;
  }
  .mob-col {
    flex-direction:column;
  }
}

.f-space {
  justify-content: space-between;
}

.f-h-center {
  align-items: center;
}

.f-v-center {
  justify-content: center;
}

$columns: 6;

.span-1, .span-2, .span-3, .span-4, .span-5, .span-6 {
  width:100%
}

@include md-breakpoint {

  .span-1 {
    width: calc(100% / $columns);
  }

  .span-2 {
    width: calc((100% / $columns) * 2);
  }

  .span-3 {
    width: calc((100% / $columns) * 3);
  }

  .span-4 {
    width: calc((100% / $columns) * 4);
  }

  .span-5 {
    width: calc((100% / $columns) * 5);
  }

  .span-6 {
    width: calc((100% / $columns) * 6);
  }

  .push-1 {
    margin-left: calc(100% / $columns);
  }

  .push-2 {
    margin-left: calc((100% / $columns) * 2);
  }

  .push-3 {
    margin-left: calc((100% / $columns) * 3);
  }

  .push-4 {
    margin-left: calc((100% / $columns) * 4);
  }

  .push-5 {
    margin-left: calc((100% / $columns) * 5);
  }
}

.medium {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}
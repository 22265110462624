body {
  font-size: clamp(18px, 1.4vw, 30px);
  line-height: normal;
  text-wrap: pretty;
}

h1 {
  font-weight: 700;
  font-size: clamp(50px, 60px, 3vw);
  line-height: 1;
  @include sm-breakpoint {
    font-size: clamp(60px, 10vw, 160px);
  }
}

h2 {
  font-weight: 700;
  line-height: 1;
  font-size: 30px;
  @include sm-breakpoint {
    font-size: clamp(50px, 6vw, 90px);
    line-height: 1;
  }
}

h3 {
  font-size: clamp(20px, 5vw, 30px);
  font-weight: 600;
  @include sm-breakpoint {
    font-size: clamp(20px, 3vw, 40px);
  }
}

h4 {
  font-weight: 600;
}

p, li {
  font-size: clamp(18px, 1.5vw, 30px);
  line-height: 1.4;
  @include md-breakpoint {
    font-size: clamp(25px, 1vw, 30px);
    line-height: 1.4;
  }
}

p.large {
  font-size: clamp(20px, 40px, 2vw);
  line-height: 1.4;
  text-wrap: pretty;
}

p.med {

}

p.small {
  font-size: clamp(20px, 0.6vw, 20px);
}


.passage {
  p {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}


b {
  font-weight: 600;
}


ul.bullets {
  li {
    margin-left: 1em;
  }
}


/*------*/
/* Global TYPE rules in one place for consistency */
/*------*/
//homepage
#featured-grid {
  .description {
    h3, p {
      @include md-breakpoint {
        font-size: clamp(20px, 1vw, 30px);
      }
    }
  }
}

// works list page
#projects-list {
  p {
    @include md-breakpoint {
      font-size: clamp(20px, 1vw, 30px);
    }
  }


  h3 {
    @include md-breakpoint {
      font-weight: 700;
      font-size: clamp(40px,3vw,90px);
      line-height: 1;
    }
  }
}

/*------*/
/* Global SPACING rules in one place for consistency */
/*------*/
//home
#featured-grid {
  .projects-grid {
    gap: 30px;
    @include md-breakpoint {
      gap: 20px;
    }
  }

  .grid-item a {
    gap: 10px;
    @include md-breakpoint {
      gap: 0;
    }
  }

  .description {
    gap: 5px;
    @include md-breakpoint {
      gap: 5px;
      padding: 20px;
    }
  }

}

// works list page
#projects-list {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include xl-breakpoint {
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2vw;
  }

  .works-item {
    a {
      gap: 10px;
    }
  }

  .item-title-desc {
    gap: 5px;
    @include xl-breakpoint {
      gap: 5px;
      flex-grow:7;
      max-width: 70%;
    }
  }

  .item-recognition {
    @include xl-breakpoint {
      flex-grow:3;
      max-width: 30%;
    }
  }


  .item-text {
    @include md-breakpoint {
      gap: 2vw;
    }
  }

}

.flex-test {
  display:flex;
  gap: 10px;
  width: 100%;
  height: 100px;
  background-color: lightgray;

  div {
    background-color: coral;
    text-align: center;
    padding: 10px;
    color: white;
  }


  div:nth-of-type(1) {
    flex-grow: 7;
  }
  div:nth-of-type(2) {
    flex-grow: 7;
  }
}
body[data-template='about'] {
  main {
    background-color: $dark;
    color: $cream;
    z-index: 4;

    & > div {
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
    div#bg-video {
      position: fixed;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: 0;
      mix-blend-mode: overlay;
      pointer-events: none;
      video {
        width:100%;
        height:100%;
        object-fit: cover;
        opacity: 0.5;

      }
    }

    & section:not(#project-hero) {
      padding-top: 50px;
      padding-bottom: 50px;

      @include sm-breakpoint {
        padding-top: 5vw;
        padding-bottom: 5vw;
      }

      & > .wrapper {
        gap: 30px;
        @include sm-breakpoint {
          gap: 70px;
        }
      }

      .title-intro {
        gap:20px;
        @include sm-breakpoint {
          gap:1vw;
        }
      }
    }

    section#clients-partners {
      .client-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3px;
        grid-template-areas:
      ". ."
      ". ."
      ". ."
      ". ."
      "your-logo your-logo"
      ". ."
      ". ."
      ". ."
      ". .";

        @include sm-breakpoint {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          gap: 0.2vw;
          grid-template-areas:
        ". . . . . ."
        ". . your-logo your-logo . ."
        ". . . . . .";
        }

        .your-logo {
          grid-area: your-logo;

          svg {
            width: 80%;

            #subtitle {
              fill:white;
            }
            #text-outlines path {
              display:none;
              stroke: white;
              stroke-width:1px;
            }
            #text-fill {
              overflow:hidden;
              fill:white;
              transform-origin: center center;
              path {
                transform-origin: center center;
              }
            }
            #subtitle {
            }
          }

/*          img {
            max-width: 300px;
            max-height: unset;
            @include sm-breakpoint {
              max-width: 18vw;
              max-height: unset;
            }
          }*/
        }

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px 20px;
          background: black;
          min-height: 150px;
          @include sm-breakpoint {
            padding: 5vw 2vw;
            min-height: unset;
          }

        }

        img {
          max-width: 130px;
          max-height: 80%;
          @include sm-breakpoint {
            max-width: 10vw;
            max-height: 5vw;
          }
        }
      }
    }

    section#services {
      background-color: $cream;
      color: $dark;

      .service-list {
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          column-gap: 20px;
          font-weight: 600;
          font-size: clamp(20px, 3vw, 40px);
          @include sm-breakpoint {
            grid-template-columns: repeat(3, 1fr);
            gap: 1vw;
            font-size: clamp(30px, 2vw, 40px);
          }

          li {
            line-height: 1;
          }

        }
      }

    }

    section#experience {

      @include xs-breakpoint {

        .entry {
          gap: 15px;
          flex-direction: column;

          .job-title {
            gap: 15px;
          }
        }
      }

      .entry {
        gap: 25px;
        flex-direction: column;
        @include sm-breakpoint {
          flex-direction: row;
          gap: unset;
        }


        .job-title {
          gap: 10px;
          @include sm-breakpoint {
            gap: 30px;
            padding-right: 2vw;
          }
        }


        .job-title img, .sub-position img {
          width: 110px;
          @include sm-breakpoint {
            width: 13vw;
            max-width: 200px;
            min-width: 150px;
          }
        }

        .desc {
          gap: 30px;

          .sub-position {
            gap: 15px;
            @include sm-breakpoint {
              gap: 15px;
            }
          }
        }
      }
    }
  }

  footer#main-footer {
    background-color: $cream;
    color: $dark;
    svg > path {
      fill:$dark;
    }
  }



}